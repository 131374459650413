.aboutMain_Container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  width: 100vw;
  margin-bottom: 80px;
  position: relative;
  .aboutGreenPlant {
    width: 335px;
    height: 335px;
    transform: rotate(-5.926deg);
    flex-shrink: 0;
    bottom: -126px;
    right: 0;
    position: absolute;
  }

  .about_rightContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 400px;
    margin-right: 0px;
    .rightContainer_footer {
      display: flex;
      flex-direction: row;
      .divider-Btn {
        border-right: 1.5px solid #078521;
        border-top: 1.5px solid #078521;
        margin-left: -14px;
        margin-top: -3.6px;
        height: 35%;
        cursor: pointer;
        width: 4%;
      }
      .rightContanier_footerBtn {
        width: 150px;
        height: 47px;
        flex-shrink: 0;
        background: #078521;
        color: #fff;
        font-family: Montserrat Alternates;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border: none;
        cursor: pointer;
        transition: background-color 0.5s ease;
      }
      .rightContanier_footerBtn:hover {
        background-color: #036316;
      }
    }
  }
  .aboutUsImg {
    width: 720px;
    height: auto;
    border-radius: 5px;
    object-fit: cover;
    z-index: -1;
  }
  .aboutTitle_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 5px;
    .aboutTitleText {
      display: flex;
      flex-direction: row;
      gap: 5px;
      color: #000;
      font-family: "Montserrat Alternates";
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .usTitleText {
      color: #078521;
    }
  }
  .aboutDescTextMain_Container {
    display: flex;
    flex-direction: column;
    .aboutDescText {
      width: 100%;
      flex-shrink: 0;
      margin-bottom: 34px;
      color: #000;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }
}

@media screen and (max-width: 665px) and (min-width: 320px) {
  .aboutMain_Container {
    display: flex !important;
    flex-direction: column-reverse !important;
    align-items: center;
    margin-bottom: 15px;
    .aboutUsImg {
      width: 90vw;
      height: 500px;
      object-fit: cover;
    }

    .about_rightContainer {
      width: 100% !important;
      margin-top: 0px;
      align-items: flex-start;
      .aboutTitle_container {
        margin-left: 35px;
        .aboutTitleText {
          font-size: 25px;
          margin-bottom: 10px;
          svg {
            width: 20px;
            height: 30px;
          }
        }
      }
      .aboutDescTextMain_Container {
        align-items: left;
        margin-left: 35px;
        .aboutDescText {
          width: 70%;
          font-size: 14px;
        }
      }
      .rightContainer_footer {
        justify-content: left;
        margin-left: 35px;
        .divider-Btn {
          height: 15px;
          margin-left: -12px;
          width: 15px;
          margin-top: -4px;
        }
        .rightContanier_footerBtn {
          width: 130px;
          font-size: 14px;
          height: 32px;
        }
      }
    }
    .aboutGreenPlant {
      bottom: 490px;
      width: 150px;
      height: 151px;
    }
  }
}

@media screen and (max-width: 1300px) and (min-width: 665px) {
  .aboutMain_Container {
    width: 100vw;
    padding: 0 65px;
    .about_rightContainer {
      width: 40%;
      .rightContainer_footer {
        .rightContanier_footerBtn {
          width: 50%;
          z-index: 1000;
        }
        .divider-Btn {
          margin-left: -11px;
          width: 13px;
          height: 13px;
        }
      }
    }
    .aboutUsImg {
      width: 43%;
    }
    .aboutDescTextMain_Container {
      width: 72%;
      .aboutDescText {
        max-width: 100%;
        font-size: 17px;
        width: 380px;
      }
    }
    .aboutGreenPlant {
      width: 300px;
      height: 300px;
    }
  }
}
