.contactUsModal {
  .ant-modal-content {
    padding: 0px 0px 0px 32px !important;
  }
  .ant-modal-close-x {
    width: 24px;
    height: 24px;
    border-radius: 23px;
    background: #078521;
    color: #fff;
    padding: 12px;
    height: 12px;
    transition: background-color 0.5s ease;
    margin-left: -4px;
  }
  .ant-modal-close-x:hover{
    background-color: #036316;
  }
  .contactUsHeader {
    font-family: Montserrat;
    color: #078521;
    line-height: 94.9%; /* 30.368px */
    font-style: normal;
    .firstHeaderText {
      width: 50%;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      padding-top: 30px;
    }
    .secondHeaderText {
      font-size: 25px;
      font-weight: 700;
      margin-bottom: 40px;
    }
  }
  .contactUsFormInput {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 12px;
    .contactUsNameInput {
      color: #64b374 !important;
      height: 32px;
      font-size: 15px;
      border: 1px solid #078521;
      border-radius: 10px;
      padding-left: 15px;
    }
    .contactUsNameInput:focus {
      outline: none;
    }
    .contactUsNameInput::placeholder {
      color: #64b374 !important;
      font-family: "Montserrat";
    }
    .contactUsEmailInput {
      height: 32px;
      font-size: 15px;
      border: 1px solid #078521;
      border-radius: 10px;
      padding-left: 15px;
      color: #64b374 !important;
    }
    .contactUsEmailInput:focus {
      outline: none;
    }
    .contactUsEmailInput::placeholder {
      color: #64b374 !important;
      font-family: "Montserrat";
    }
    .contactUsMessageInput {
      max-width: 92.5%;
      min-width: 92.5%;
      max-height: 120px !important;
      min-height: 70px;
      overflow-y: auto;
      font-size: 15px;
      border: 1px solid #078521;
      border-radius: 10px;
      padding-left: 15px;
      color: #64b374 !important;
    }
    .contactUsMessageInput:focus {
      outline: none;
    }
    .contactUsMessageInput::placeholder {
      color: #64b374 !important;
      font-family: "Montserrat";
    }
  }
  .contactUsModal_BodyContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    .contactUsModalImage {
      margin-top: 68px;
      margin-left: 8px;
    }
  }
  .contactUsBtnSubmit {
    text-align: center;
    margin: 32px 0;
    .sendMessage {
      border-radius: 5px;
      background: #078521;
      transition: background-color 0.5s ease;
      color: #fff;
    }
    .sendMessage:hover{
      background-color: #036316;
    }
  }
}

@media screen and (max-width: 490px) and (min-width: 320px) {
  .contactUsModal {
    .ant-modal-content {
      padding: 0px !important;
      .contactUsModal_BodyContainer {
        .contactUsModalImage {
          margin-top: 0px;
        }
      }
      .contactUsHeader {
        .firstHeaderText {
          width: 100%;
        }
        .secondHeaderText {
          text-align: center;
        }
      }
      .contactUsModal_BodyContainer {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }
}