.service-container {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #078521;
  margin-bottom: 44px;
  padding: 12px 0;
  .image-mainContainer {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    max-width: 1245px;
    padding: 0 40px;
    .image-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      font-family: Montserrat;
      font-size: 21px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      transition: all 0.15s ease-in-out;
      transform: translate(0);
      position: relative;
      cursor: pointer;
      width: 22%;
      height: 180px;
      border-radius: 12px;
      overflow: hidden;
      img {
        width: 100%;
        min-width: 270px;
        height: 100%;
        object-fit: cover;
      }
      .image-Text {
        position: absolute;
        bottom: 0;
        text-align: center;
        width: 100%;
        height: 30px;
        padding-top: 5px;
        backdrop-filter: blur(3px);
        color: #fff;
        background: rgba(0, 87, 18, 0.5);
        border-radius: 0px 0px 13px 13px;
      }
      .image-Text-open {
        text-align: center;
        width: 99%;
        font-size: 6px;
        padding: 5px;
        backdrop-filter: blur(3px);
        color: #fff;
        background: rgba(0, 87, 18, 0.5);
        border-radius: 0px 0px 13px 13px;
        position: absolute;
        bottom: 0;
      }
      .additional-text {
        text-align: center;
        width: 91%;
        padding: 10px 35px;
        backdrop-filter: blur(2px);
        font-family: Montserrat;
        color: #fff;
        background: rgba(0, 87, 18, 0.5);
        border-radius: 0px 0px 51px 40px;
      }
    }
    .image-container:hover {
      transform: scale(1.1); 
    }
    .image-container-open {
      display: flex;
      align-items: center;
      flex-direction: column;
      font-family: Montserrat;
      font-size: 21px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      transition: all 0.15s ease-in-out;
      transform: translate(0);
      position: relative;
      cursor: pointer;
      width: 22%;
      height: 180px;
      border-radius: 12px;
      overflow: hidden;
      img {
        width: 100%;
        min-width: 270px;
        height: 100%;
        object-fit: cover;
      }
      .image-Text {
        position: absolute;
        bottom: 0;
        text-align: center;
        width: 100%;
        height: 30px;
        padding-top: 5px;
        backdrop-filter: blur(3px);
        color: #fff;
        background: rgba(0, 87, 18, 0.5);
        border-radius: 0px 0px 13px 13px;
      }
      .image-Text-open {
        text-align: center;
        width: 99%;
        font-size: 6px;
        padding: 5px;
        backdrop-filter: blur(3px);
        color: #fff;
        background: rgba(0, 87, 18, 0.5);
        border-radius: 0px 0px 13px 13px;
        position: absolute;
        bottom: 0;
      }
      .additional-text {
        text-align: center;
        width: 91%;
        padding: 10px 35px;
        backdrop-filter: blur(2px);
        font-family: Montserrat;
        color: #fff;
        background: rgba(0, 87, 18, 0.5);
        border-radius: 0px 0px 51px 40px;
      }
    }
    .image-container-open:hover {
      transform: scale(1); 
    }
  }
}

@media screen and (max-width: 700px) {
  .service-container {
    margin-bottom: 25px;
    height: 80px;
    .image-mainContainer {
      width: 98%;
      gap: 5px;
      justify-content: space-between;
      margin-left: 0px;
      padding: 5px 23px;
      .image-container {
        min-width: 75px;
        width: 100%;
        min-height: 60px !important;
        height: 100%;
        img {
          min-width: 65px;
          min-height: 45px !important;
        }
        .image-Text {
          font-size: 8px;
          height: 15px;
        }
      }
    }
  }
}
@media screen and (min-width: 700px) and (max-width: 1000px) {
  .service-container {
    height: 135px;
    .image-mainContainer {
      .image-container {
        min-height: 130px !important;
        height: 130px;
        img {
          min-width: 180px;
        }
        .image-Text {
          font-size: 14px;
          height: 20px;
        }
      }
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 440px) {
  .service-container {
   
    .image-mainContainer {
     
      .image-container {
       
       
        .image-Text-open {
          font-size: 3px;
        }
      }
    }
  }
}
@media screen and (min-width: 440px) and (max-width: 700px) {
  .service-container {
   
    .image-mainContainer {
     
      .image-container {
       
       
        .image-Text-open {
          font-size: 4px;
        }
      }
    }
  }
}
.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9;
}
