@font-face {
  font-family: Montserrat;
  src: url(../../assets/Fonts/Montserrat-Bold.ttf);
}
@font-face {
  font-family: Montserrat L;
  src: url(../../assets/Fonts/Montserrat-Light.ttf);
}
@font-face {
  font-family: Montserrat Alternates;
  src: url(../../assets/Fonts/MontserratAlternates-Bold.ttf);
}

.descriptionMain_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 930px;
  min-height: 100vh;
  position: relative;
  .navbar_logo {
    min-height: 119px;
    margin-top: 20px;
    max-width: 119px !important;
  }
  .logo-text {
    max-width: 165px !important;
    font-size: 40px;
    color: #078521;
    font-family: "Montserrat" !important;
    font-weight: 600;
  }
  .phone-icon {
    margin-top: -130px;
  }
  .referBtn_Contaniner {
    width: 100%;
    max-width: 310px;
    display: flex;
    justify-content: center;
    margin-bottom: 22px;
    .referBtn {
      width: 82%;
      max-width: 200px;
      height: auto;
      color: #fff9f9;
      font-family: "Montserrat" !important;
      font-size: 17px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      background: #000;
      padding: 5px;
      border-radius: 12px;
      transition: background-color 0.5s ease;
    }
    .referBtn:hover {
      background-color: rgba(0, 0, 0, 0.701);
    }
  }
  .hasSubWelcome {
    width: 100%;
    text-align: center;
    color: #000;
    font-family: "Montserrat Alternates";
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 94.9%;
  }
  .firstTextDescp {
    color: #000;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    margin-top: 35px;
    line-height: 84.4%;
    margin-bottom: 5px;
  }
  .secondTextDescp {
    color: #000;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    line-height: 84.4%;
    z-index: 999;
  }
  .additional-text {
    font-family: "Montserrat L";
    width: 100%;
    min-height: auto;
    margin-top: 20px;
    max-width: 610px;
    min-width: 580px;
    text-align: center;
    transform: translateY(30px);
    visibility: hidden;
    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
      opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition-delay: 0.449s;
    animation: fadeInDown 0.6s ease-in-out forwards;
    animation-delay: 0.449s;
  }
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
  .joinWaitlist {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 45px;
    color: #7fbb48;
    font-family: Montserrat Alternates;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
  .emailInputSubmit {
    width: 52%;
    min-width: 570px;
    max-width: 665px;
    height: 52px;
    display: flex;
    align-items: center;
    color: #fff;
    background: #ededed;
    margin: 25px 0;
    border-radius: 12px;
    box-shadow: 1px 3px 5px 1px rgba(0, 0, 0, 0.28);
    z-index: none;
    .submit-email {
      width: 65%;
      height: 52px;
      border: none;
      border-radius: 12px;
      font-family: Montserrat;
      font-weight: 700;
      font-size: 22px;
      background: #000;
      box-shadow: -5px 0px 10px 0px rgba(0, 0, 0, 0.28);
      z-index: 8;
      transition: background-color 0.5s ease;
      cursor: pointer;
      color: #fff;
    }
    .emailInput {
      width: 75%;
      border-radius: 30px;
      background: #ededed;
      font-family: "Montserrat";
      font-weight: 500;
      border: none;
      margin-left: 20px;
      font-size: 22px;
      color: #5a5a5a;
    }
    .emailInput::-webkit-input-placeholder {
      color: #5a5a5a;
      opacity: 1;
    }
    .emailInput:focus {
      outline: none;
    }
  }
  .joinedInfo_Container {
    width: 94%;
    color: #000;
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 45px;
    margin-top: 10px;
  }

  .phone-icon {
    width: 160vw;
    max-width: 1088px;
    // position: absolute;
    // bottom: -70px;
    // margin-top: -153px;
    z-index: -1;
  }
  .animation {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 620px) {
  .descriptionMain_Container {
    .phone-icon {
      margin-top: 0px;
    }
    .animation {
      margin-bottom: 80px;
    }
    .firstTextDescp {
      font-size: 23px !important;
      width: 100%;
    }
    .additional-text {
      max-width: 610px;
      width: 60%;
      min-width: 335px;
      margin-bottom: 20px;
    }
    .secondTextDescp {
      width: 100% !important;
      font-size: 23px;
    }
    .joinWaitlist {
      color: #000;
      font-family: Montserrat;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      svg {
        width: 11px !important;
        height: 11px !important;
      }
    }
    .hasSubWelcome {
      width: 60%;
      font-size: 10px;
    }
    .emailInputSubmit {
      width: 80%;
      min-width: 0px;
      max-width: 375px;
      // margin-bottom: 85px;
      height: 37px;
      border-radius: 9px;
      .emailInput {
        margin-bottom: 5px;
      }
      svg {
        margin-top: 3px;
      }
      .submit-email {
        width: 65%;
        height: 37px;
        font-size: 11px;
        border-radius: 9px;
      }
    }
    .emailInput::placeholder {
      font-size: 14px;
    }
    .joinedInfo_Container {
      font-size: 9px;
    }
    .referBtn_Contaniner {
      width: 50%;
      z-index: 100;
      justify-content: center;
      margin-bottom: 12px;
      .referBtn {
        font-size: 14px;
      }
    }
  }
}

@media screen and (min-height: 1115px) {
  .descriptionMain_Container {
    .phone-icon {
      margin-top: 0px;
    }
    .firstTextDescp {
      margin-top: 100px;
    }
  }
}

.store-buttons {
  padding-top: 20px;
  gap: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  img {
    height: 45px;
  }
  @media screen and (max-width: 620px) {
    img {
      height: 40px;
    }
  }
}
