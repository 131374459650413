.AdvantagesMain_Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 200px;
  width: 100vw;
  background: #159e31;
  padding: 50px 0px 0px 0px;
  background-image: url("../../assets/AdvantagesIcons/AdvantageImg.png");
  background-repeat: no-repeat;
  background-size: cover;
  .AdvantagesLeft_Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 720px;
    margin-left: 45px;
    margin-bottom: 45px;
    .AdvantagesTitle {
      display: flex;
      flex-direction: row;
      gap: 10px;
      color:#fff;
      font-family: Montserrat Alternates;
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 30px;
      .firtsPartTitle {
        color: #fff;
      }
      .secondPartTitle {
        color: #b9f18d;
      }
    }
    .leftCardMain_Container {
      display: flex;
      flex-direction: column;
      gap: 30px;
      .Card_Container {
        border-radius: 26px;
        width: 420px;
        height: 175px;
        background: #fff;
        box-shadow: 5px 6px 4px 0px rgba(0, 0, 0, 0.09);
      }
      .ant-card-head {
        border-bottom: none;
        margin-bottom: -25px;
        .ant-card-head-title {
          margin-left: 35px;
          color: #000;
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
      .ant-card-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 25px;
        padding: 0px;
      }
      .cardIcon_Container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 85px;
        height: 76px;
        border-radius: 50%;
        margin-left: -65px;
        margin-bottom: 30px;
        background: #078521;
      }
      .cardBodyDescp {
        width: 91%;
        color: #000;
        font-family: "Montserrat";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  .AdvantagesRight_Container {
    max-width: 720px;
    height: 77%;
    border: 3px solid #fff;
    .rightPicture_Container {
      width: 100%;
      margin-left: -15px;
      margin-top: 15px;
      margin-bottom: 15px;
      box-shadow: 5px 6px 4px 0px rgba(0, 0, 0, 0.09);
    }
  }
}
@media screen and (max-width: 1320px) and (min-width:950px) {
  .AdvantagesMain_Container{
    gap:40px;
     .AdvantagesLeft_Container {
     max-width: 42%;

     .leftCardMain_Container{

      .Card_Container{
           width: 360px;
           height: 190px;
     }
    }
  }
   .AdvantagesRight_Container{
    max-width: 38%;
   }
}

}

@media screen and (max-width: 950px) and (min-width: 665px) {
  .AdvantagesMain_Container {
    gap: 30px;
    flex-direction: column;
    align-items: start;
    .AdvantagesLeft_Container {
      margin-left: 105px;
      align-items: start;
      .leftCardMain_Container {
        width: 90vw;
        .AdvantagesMain_Container .AdvantagesRight_Container {
          width: 55%;
          height: 70%;
        }
        .Card_Container {
          width: 60%;
          height: 140px;
        }
        .cardBodyDescp {
          font-size: 12px;
        }
        .Card_Container .ant-card-head .ant-card-head-title {
          font-size: 17px;
        }
      }
    }
    .AdvantagesRight_Container {
      width: 460px;
      margin-left: 105px;
    }
  }
}


@media screen and (max-width: 665px) and (min-width: 320px) {
  .AdvantagesMain_Container {
    padding-top: 25px;
    flex-direction: column;
    gap: 25px;
    .AdvantagesLeft_Container {
      width: 80%;
      margin-left: 20px;
      margin-bottom: 0px;
      .AdvantagesTitle {
        margin-right: 20px;
        margin-bottom: 0px;
        font-size: 25px !important;
        svg {
          width: 20px;
          height: 30px;
        }
      }
      .leftCardMain_Container {
        flex-direction: column;
        .Card_Container {
          width: 240px;
          height: 160px;
        }
        .cardBodyDescp {
          font-size: 10px;
        }
        .Card_Container {
          .ant-card-head .ant-card-head-title {
            margin-left: 10px;
            font-size: 11px;
          }
          .ant-card-body {
            gap: 6px;
            .cardIcon_Container {
              width: 58px;
              height: 50px;
              margin-left: -30px;
              svg {
                width: 30px;
              }
            }
          }
        }
      }
    }
    .AdvantagesRight_Container {
      width: 75%;
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }
}
